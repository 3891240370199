.bunader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        color: #1c3042;
        font-family: "Merriweather", serif;
        font-size: 2em;
        margin: 0.67em 0;
        text-align: center;
    }

    &__image-wrapper {
        width: 60%;
        margin: auto;
        background-color: $color-background-primary;

        @media (max-width: $mq-m) {
            width: 100%;
        }

        @media (min-width: $mq-m) {
            width: 70%;
        }
    }

    &__image {
        height: 11rem;
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0;
        background-position: 100% 10%;
        transition: opacity $animation-speed-regular $animation-type-regular;

        @media (min-width: $mq-m) {
            height: 25rem;
        }
    }

    .u-fade-in {
        opacity: 1;
    }
}
