.home-menu {
    position: relative;

    &__container {
        position: relative;
    }

    &__image-wrapper {
        background-color: $color-background-primary;
    }

    &__image {
        height: 40rem;
        background-size: cover;
        object-fit: cover;
        object-position: 100% 0;
        opacity: 0;
        transition: opacity $animation-speed-regular $animation-type-regular;

        @media (max-width: $mq-m) {
            height: 28rem;
        }
    }

    &__info {
        h1 {
            color: $color-background;
            font-family: 'Merriweather', serif;
            @media (max-width: $mq-m) {
                margin: 0.67rem;
            }
        }
        position: absolute;
        top: 35%;
        margin: 0 auto;
        left: 1rem;
        right: 0;
    }

    .u-fade-in {
        opacity: 1;
    }
}

.btn--secondary {
    background-color: $color-secondary;
    margin-right: $margin-m;
    padding: 0.7rem;
    width: 150px;
    box-shadow: initial;
    color: $color-background;
    &:hover {
        background-color: $color-secondary-hover;
        color: $color-background;
    }
}