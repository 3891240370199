.google-map {
  height: 600px;
  width: 700px;
  margin-right: 100px;
  margin-left: 100px;

  @media (max-width: $mq-l) {
    height: 400px;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: $margin-xl;
  }
}

.clickable-marker {
    cursor: pointer;
  }