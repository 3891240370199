.contactform {
    .input-form {
        background-color: $color-background;
        width: 600px;
        height: $margin-l;
        border: none;

        @media (max-width: $mq-m) {
            width: 100%;
        }

        @media (max-width: 1000px) {
            width: 400px;
        }
    }

    .textarea-form {
        background-color: $color-background;
        width: 600px;
        height: 250px;
        border: none;

        @media (max-width: $mq-m) {
            width: 100%;
        }

        @media (max-width: 1000px) {
            width: 400px;
        }
    }

    .button-form {
        text-align: center;
        margin: $margin-m;

        button {
            float: right;

            @media (max-width: $mq-m) {
                width: 150px;
                float: none;
            }
        }
    }

}