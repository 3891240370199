.footer {
    background-color: $color-primary;

    &__container {
        position: relative;
        display: flex;
        flex-direction: row;
        padding: $margin-m $margin-l 0 $margin-l;
        justify-content: space-between;

        h3 {
            color: $color-white;
            font-weight: 700;
            font-family: 'Merriweather', serif;
        }

        @media (max-width: $mq-m) {
            padding: 0;
        }
    }

    &__info {
        padding: 0.8rem;
        width: 70%;

        @media (max-width: $mq-m) {
            h3 {
                font-size: 1.2rem;
                max-width: 180px;
                text-align: left;
            }

            text-align: center;
            align-items: center;
            justify-content: center;

        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @media (max-width: $mq-m) {
            text-align: left;
            align-items: center;
            font-size: 1.1rem; 
            line-height: 3rem;

        }
    }

    &__bunadlogo {

        @media (max-width: $mq-m) {
            width: 500px;
            height: 288px;
        }
    }

    &__bunadinfo {
        color: white;

        span {
            font-size: $font-base;
        }

        &__items {
            display: flex;
            flex-direction: column;
            font-size: 1.2rem;


            span {
                font-size: 1rem;
                margin-bottom: 0.5rem;
            }
        }

        @media (max-width: $mq-m) {
            h3 {
                font-size: 1.1rem;
            }
        }
    }

    .opening-hours,
    .contact-info {
        display: block;

        @media (max-width: $mq-m) {
            display: none;
        }
    }

    .footer-link {
        color: $color-background;
        font-size: 14px;

        @media (max-width: $mq-m) {
            font-size: 1.2rem;
            text-align: center;

        }
    }

}