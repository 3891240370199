.bunadlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $margin-m;

    h1 {
        color: $color-primary;
        font-family: 'Merriweather', serif;
    }

    .bunadlink {
        margin-top: $margin-xl;
        align-self: center;
        width: 200px;
        @media (max-width: $mq-m) {
            margin-top: $margin-m;
        }
    }

    &__wrapper {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-items: center;
        grid-gap: $margin-m;
        color: #444;


        @media (max-width: $mq-m) {
            grid-template-columns: 0;
            grid-row: 1;
        }
    }

    &__item {
        color: #fff;
        border: 2px solid $color-secondary;
        border-radius: 2px;
        font-size: $font-base;

        h2 {
            text-align: center;
            color: $color-primary;
            font-size: $font-big;
            font-weight: 700;
        }

        &__wrapper {
            background-color: $color-background-primary;

            img {
                object-fit: cover;
                object-position: 100% 0;

                width: 350px;
                height: 400px;

                @media (max-width: $mq-m) {
                    width: 250px;
                    height: 300px;
                }
            }
        }
    }
}