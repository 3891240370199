.info-list {
    display: flex;
    flex-direction: row;
    margin: 1rem;
    text-align: center;
    justify-content: center;

    @media (max-width: $mq-m) {
        flex-direction: column;
    }

    &__item {
        margin: $margin-m;
        width: 200px;

        span {
            font-size: 1rem;
        }

        @media (max-width: $mq-m) {
            width: auto;

            span {
                font-size: 14px;
            }
        }
    }
}