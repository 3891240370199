.contact {

    @media (max-width: $mq-m) {
        margin: 1rem;
        h1 {
            text-align: center;
            font-family: 'Merriweather', serif;
        }
    }

    margin: 0 5rem $margin-xl 5rem;

    &__info-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @media (max-width: $mq-m) {
            margin: 1rem;
            flex-direction: column;
        }
    }

    &__info_item {
        flex: 1 0;
        &:first-child {
            margin-right: 224px;
        }

        h2 {
            font-size: $font-big;
        }

        h1,
        h2 {
            color: $color-primary;
            margin-top: 4px;
            margin-bottom: 4px;
            font-family: 'Merriweather', serif;
        }

        p {
            margin-block-start: 0;
            margin-block-end: 0;
        }

        p,
        span {
            font-size: 0.9rem;
        }

        @media (max-width: $mq-m) {
            &:first-child {
                margin-right: 0;
            }
            p,
            span {
                font-size: $font-base;
            }
        }

        &.item3 {
            margin-bottom: 1rem;
        }
    }

    .info-paragraph {
        margin-bottom: $margin-xl;
    }

    &__info-map-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width: $mq-m) {
            flex-direction: column-reverse;
            align-items: inherit;
        }
    }
}