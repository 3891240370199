html {
    box-sizing: border-box;
    body {
        background-color: white;
        font-family: 'Roboto', sans-serif;
    }
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.App {
    overflow-x: hidden;
}

body.is-blocked {
    overflow: hidden;
}

.btn-primary {
    color: $color-background;
    background-color: $color-primary;
    border:none;
    font-size: $font-base;
    padding: 0.8rem;
    width: 150px;
    &:focus {
        background-color: $color-primary;
    }

    &:active {
        background-color: $color-primary !important;
    }

    &:hover {
        background-color: $color-primary-hover;
    }
    &:disabled {
        background-color: $color-tertiary;
    }
}