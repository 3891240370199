a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;

    @media (max-width: $mq-m) {
        text-align: left;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-brand-container {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;

    span {
        color: $color-primary;
        font-size: $font-base;
        font-weight: 700;
        margin: auto;
        margin-left: $margin-s;
        word-break: break-word;
        width: 130px;
        text-align: left;
    }
}

.navbar-container {
    position: relative;
    display: flex;
    margin: 0 5rem 0 5rem;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1 0 auto;

    @media (max-width: $mq-m) {
        display: flex;
        margin: 0;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
}

.navbar-dark {
    background-color: $color-white;
    @media (max-width: $mq-m) {
        background-color: $color-primary;
        padding: $margin-m;
    }
}

.navbar-toggler {
    @media (max-width: $mq-m) {
        right: 0;
        position: absolute;
        outline-color: white !important;
    }
}

.navbar-header {
    width: 200px;
    margin-top: 0;
    color: white;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;

}

.navbar-nav {
    @media (max-width: $mq-m) {
        padding-top: 1rem;
        font-size: 0.6rem;
    }
}
  

.navbar-navlink {
    padding: 0;
    color: $color-primary !important;
    font-family: 'Merriweather', serif;
    &:hover {
        border-bottom: 1px solid $color-primary;
    }

    @media (max-width: $mq-m) {
        color: white !important;
        text-align: center;
        font-size: 1.1rem; 
        line-height: 3rem;
        &:hover {
            border-bottom: none;
        }
    }

}