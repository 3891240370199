.about {

    h1 {
        font-size: 1.5rem;
        font-family: 'Merriweather', serif;
    }

    h1,
    h2 {
        color: $color-primary;
        font-family: 'Merriweather', serif;
    }

    p {
        color: $color-tertiary;
        font-size: $font-base;
    }

    &__container {
        display: flex;
        flex-direction: row;
        margin: $margin-l;
        padding: $margin-m;
        background-color: $color-background;

        p {
            font-size: $font-base;
        }

        h1 {
            font-size: 1.5rem;
            font-family: 'Merriweather', serif;
        }

        h2 {
            font-family: 'Merriweather', serif;
        }

        @media (max-width: $mq-m) {
            flex-direction: column;
            margin: 0;
            padding: 0;
            background-color: #FFFFFF;

            h1,
            h2 p {
                font-size: 1.2rem;
            }
        }
    }

    &__intro {
        margin: 2rem 0 0 2rem;

        p {
            width: 350px;
        }

        @media (max-width: $mq-m) {
            p {
                width: 100%;
            }
        }
    }

    &__intro,
    &__info {
        width: 60%;

        p {
            font-size: 1rem;
        }

        @media (max-width: $mq-m) {
            margin: $margin-m;
            width: auto;

            h1,
            h2 {
                font-size: 1.5rem;
                font-family: 'Merriweather', serif;
            }

            h3 {
                font-family: 'Merriweather', serif;
            }

            h3,
            p {
                font-size: 1.2rem;
            }

            p {
                &:first-child {
                    width: 100%;
                }
            }
        }
    }

    &__info {
        margin: auto;

        @media (max-width: $mq-m) {
            margin: $margin-m;
        }
    }

    &__image {
        position: relative;
        height: 19rem;
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: cover;

        @media (min-width: $mq-m) {
            margin-right: 0;
            height: 25rem;
            width: 100%;
        }
    }

    &__content {
        margin-left: $margin-xl;

        @media (max-width: $mq-m) {
            margin-left: 0;

        }
    }

    &__button-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin: auto;
        width: 100%;
        text-align: center;
        justify-content: center;

        @media (max-width: $mq-m) {
            justify-content: space-evenly;
            margin: $margin-l $margin-m $margin-l 0;
        }

        .btn-primary {
            &:first-child {
                margin-right: $margin-m;
            }

            &:hover {
                background-color: $color-primary-hover;
                color: $color-background;
            }
        }
    }

    .u-fade-in {
        opacity: 1;
    }
}